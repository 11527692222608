import { withFirebase } from "./firebase"

export const fatal = async (
  method: string,
  description: string
): Promise<void> => {
  withFirebase((firebase) =>
    firebase.analytics().logEvent("exception", {
      fatal: true,
      method,
      description: `${method}::${description}`,
    })
  )
}

export const requestDocument = async (): Promise<void> => {
  withFirebase((firebase) =>
    firebase.analytics().logEvent("request_document", {})
  )
}

export const requestTrial = async (): Promise<void> => {
  withFirebase((firebase) => firebase.analytics().logEvent("request_trial", {}))
}
